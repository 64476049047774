import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import sessionPlanApi from '../../shared/services/instructor/sessionPlan';

export function* getSessionPlans(action: {
      type: string;
      values: {
        query: [];
          trackId: string;
          regimeNumber: number;
      };
    }) {
  try {
    const result: ReturnType<
          typeof sessionPlanApi.getSessionPlans
        > = yield call(sessionPlanApi.getSessionPlans, action.values);
    yield put({
      type: constants.getSessionPlans.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getSessionPlans.failure,
      e,
    });
  }
}
export function* getSessionPlansWatcher() {
  yield takeEvery(
    constants.getSessionPlans.request,
    getSessionPlans,
  );
}

export function* setPaginationIndex(action: { type: string; body: number }) {
  try {
    yield put({
      type: constants.setPaginationIndex.success,
      request: action.body,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setPaginationIndex.failure,
      e,
    });
  }
}

export function* setPaginationIndexWatcher() {
  yield takeEvery(constants.setPaginationIndex.request, setPaginationIndex);
}

export function* getSessionPlanDetails(action: {
  type: string;
  values: {
    query: [];
      trackId: string;
      orderNumber: number;
      regimeNumber: number;
  };
}) {
  try {
    const result: ReturnType<
      typeof sessionPlanApi.getSessionPlanDetails
    > = yield call(sessionPlanApi.getSessionPlanDetails, action.values);
    yield put({
      type: constants.getSessionPlanDetails.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getSessionPlanDetails.failure,
      e,
    });
  }
}
export function* getSessionPlanDetailsWatcher() {
  yield takeEvery(
    constants.getSessionPlanDetails.request,
    getSessionPlanDetails,
  );
}

export default function* sessionPlanSaga() {
  yield all([
    getSessionPlansWatcher(),
    setPaginationIndexWatcher(),
    getSessionPlanDetailsWatcher(),
  ]);
}
