import React from 'react';

type SessionIconProps = {
  className?: string;
};

const SessionIcon: React.FC<SessionIconProps> = ({ className }) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.15625 2H8.09375V0.90625C8.09375 0.550781 8.36719 0.25 8.75 0.25C9.10547 0.25 9.40625 0.550781 9.40625 0.90625V2H10.5C11.457 2 12.25 2.79297 12.25 3.75V12.5C12.25 13.4844 11.457 14.25 10.5 14.25H1.75C0.765625 14.25 0 13.4844 0 12.5V3.75C0 2.79297 0.765625 2 1.75 2H2.84375V0.90625C2.84375 0.550781 3.11719 0.25 3.5 0.25C3.85547 0.25 4.15625 0.550781 4.15625 0.90625V2ZM1.3125 12.5C1.3125 12.7461 1.50391 12.9375 1.75 12.9375H10.5C10.7188 12.9375 10.9375 12.7461 10.9375 12.5V5.5H1.3125V12.5Z"
      fill="#176FFF"
    />
  </svg>
);

export default SessionIcon;

SessionIcon.defaultProps = {
  className: '',
};
