import React from 'react';

type TrackPlannerIconProps = {
  className?: string;
};

const TrackPlannerIcon: React.FC<TrackPlannerIconProps> = ({ className }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.625 1.625C2.625 1.16016 3.00781 0.75 3.5 0.75C3.96484 0.75 4.375 1.16016 4.375 1.625V2.5H7.875V1.625C7.875 1.16016 8.25781 0.75 8.75 0.75C9.21484 0.75 9.625 1.16016 9.625 1.625V2.5H10.9375C11.6484 2.5 12.25 3.10156 12.25 3.8125V5.125H0V3.8125C0 3.10156 0.574219 2.5 1.3125 2.5H2.625V1.625ZM12.25 13.4375C12.25 14.1758 11.6484 14.75 10.9375 14.75H1.3125C0.574219 14.75 0 14.1758 0 13.4375V6H12.25V13.4375Z"
      fill="#176FFF"
    />
  </svg>
);

export default TrackPlannerIcon;

TrackPlannerIcon.defaultProps = {
  className: '',
};
