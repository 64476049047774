const account = {
  profiles: {
    profileHandler: 'account/profiles',
    getProfiles: (email: string) => `account/profiles/${email}`,
    switchProfile: (profileId: string) => `account/profiles/${profileId}/default`,
  },
};

const auth = {
  refreshAccessToken: 'auth/refresh-token',
  changePassword: 'auth/change-password',
};

const instructor = {
  checkpoints: {
    getAllCheckpoints: 'instructor/checkpoints',
    getCheckpointById: (checkpointAssignmentId: string) => `instructor/checkpoints/${checkpointAssignmentId}`,
    addReview: (checkpointAssignmentId: string) => `instructor/checkpoints/${checkpointAssignmentId}/reviews`,
    approveCheckpoint: (checkpointAssignmentId: string) => `instructor/checkpoints/${checkpointAssignmentId}/approved`,
    getOrderedCheckpoints: (trackId: string) => `instructor/checkpoints/tracks/${trackId}/ordered`,
  },
  feedbacks: {
    fetchInstructorNps: 'instructor/feedbacks/stats/me',
  },
  groups: {
    getActiveStudents: (groupId: string) => `instructor/groups/${groupId}/students/active/new`,
    fetchAllGroups: 'instructor/groups',
    fetchGroupById: (id: string) => `instructor/groups/${id}`,
    fetchGroupByIdActive: (id: string) => `instructor/groups/${id}/active`,
    fetchAllActiveGroups: 'instructor/groups/active',
  },
  instructors: {
    getTracks: 'instructor/instructors/me/tracks',
  },
  labPhases: {
    fetchAlllabphaseApplications: 'instructor/lab-phases/applications',
    fetchAllLabPhases: 'instructor/lab-phases',
    getAllLabPhaseAssignments: 'instructor/lab-phases/assignments',
    approvePhase: (phaseAssignmentId: string) => `instructor/lab-phases/phases/${phaseAssignmentId}/approved`,
    fetchTopStudents: (labPhaseId: string) => `stats/lab-phases/${labPhaseId}/scores`,
    fetchLabPhaseById: (labPhaseId: string) => `instructor/lab-phases/${labPhaseId}`,
    fetchLabPhaseStats: 'stats/lab-phases',
    getLabPhaseAssignmentsByID: (labPhaseId: string, studentId: string) => `instructor/lab-phases/${labPhaseId}/students/${studentId}/request`,
    getLabPhaseAssignmentByID: (id: string) => `instructor/lab-phases/assignments/${id}`,
    getReviewsByAssignmentId: (phaseAssignmentId: string) => `instructor/lab-phases/phases/${phaseAssignmentId}/reviews`,
    addReview: (phaseAssignmentId: string) => `instructor/lab-phases/phases/${phaseAssignmentId}/reviews`,
    getStudentLabPhaseAssignmentsBy: (id: string) => `instructor/lab-phases/assignments/${id}`,
    approveRequest: (labPhaseId: string, studentId: string) => `instructor/lab-phases/${labPhaseId}/students/${studentId}/request/approved`,
    rejectRequest: (labPhaseId: string, studentId: string) => `instructor/lab-phases/${labPhaseId}/students/${studentId}/request/rejected`,
    fetchPhaseAssignmentById: (phaseId: string, trackId: string) => `assignment/lab-phases/phases/${phaseId}/tracks/${trackId}`,
    approveLabPhase: (labPhaseAssignmentId: string) => `instructor/lab-phases/${labPhaseAssignmentId}/approved`,
    getLabPhaseAssignments: 'instructor/lab-phases/assignments',
    getLabPhaseAssignment: 'instructor/lab-phases/assignments',
  },
  oneToOnes: {
    fetchAllOneToOnes: 'instructor/one-to-ones',
    fetchOneToOneById: (oneToOneAssignmentId: string) => `instructor/one-to-ones/${oneToOneAssignmentId}`,
    startOneToOne: (oneToOneAssignmentId: string) => `instructor/one-to-ones/${oneToOneAssignmentId}/started`,
    updateFaceToFaceStudentAnswer: (oneToOneAssignmentId: string) => `instructor/one-to-ones/${oneToOneAssignmentId}`,
    sendFaceToFaceInstructorFeedback: (oneToOneAssignmentId: string) => `instructor/one-to-ones/${oneToOneAssignmentId}/concluded`,
    getOrderedOneToOnes: (trackId: string) => `instructor/one-to-ones/tracks/${trackId}/ordered`,
  },
  automatedOneToOnes: {
    fetchAutomatedOneToOneById: (oneToOneAssignmentId: string) => `instructor/one-to-ones/automated/${oneToOneAssignmentId}`,
    updateStudentAnswer: (oneToOneAssignmentId: string) => `instructor/one-to-ones/automated/${oneToOneAssignmentId}`,
    sendInstructorFeedback: (oneToOneAssignmentId: string) => `instructor/one-to-ones/automated/${oneToOneAssignmentId}/concluded`,
  },
  profiles: {
    fetchInstructorDetails: 'instructor/profiles/me/details',
  },
  students: {
    getAllStudents: 'instructor/students/details',
    getStudentById: (id: string) => `instructor/students/${id}`,
    getStudentStats: (id: string) => `instructor/students/${id}/stats`,
  },
  tracks: {
    patchBookMark: (trackId: string, nodeId: string) => `instructor/tracks/${trackId}/nodes/${nodeId}/bookmarked`,
    getBookMarkedNodes: (trackId: string) => `instructor/tracks/${trackId}/bookmarked-nodes`,
    patchResources: (trackId: string) => `instructor/tracks/${trackId}/resources`,
    getResources: (trackId: string) => `instructor/tracks/${trackId}/resources`,
    getTracks: 'instructor/instructors/me/tracks',
  },
  workshop: {
    getOrderedWorkshops: (trackId: string) => `instructor/workshops/tracks/${trackId}/ordered`,
  },
  sessionPlans: {
    getSessionPlans: (trackId: string, regimeNumber: number) => `instructor/session-plans/track/${trackId}/regime/${regimeNumber}`,
    getSessionPlanDetails: (trackId: string, orderNumber: number, regimeNumber: number) => `instructor/session-plans/track/${trackId}/order/${orderNumber}/regime/${regimeNumber}`,
  },
};

const store = {
  diploma: {
    getStudentDiploma: (studentId: string, trackId: string) => `store/diplomas/tracks/${trackId}/students/${studentId}`,
  },
  tracks: {
    getTracksByLocationId: (id: string) => `store/tracks/locations/${id}`,
    getSkeleton: (trackId: string) => `store/tracks/${trackId}/skeleton`,
    getTrackStats: (trackId: string) => `store/tracks/${trackId}/stats`,
  },
};

const ranked = {
  rank: {
    getLeaderBoardStudents: (trackId: string) => `ranked/league/tracks/${trackId}/local/leader-board`,
  },
};

const capacity = {
  allocations: {
    postSkillFeedback: 'capacity/allocations/support-tickets/skill',
  },
  openings: {
    getPlannedSessions: (
      startDateUtc: string,
      dueDateUtc: string,
      productScheduleId: string,
    ) => `capacity/openings/${startDateUtc}/${dueDateUtc}/schedules/${productScheduleId}/session-instances`,
  },
  studentAbsences: {
    getStudentAbsence: (studentId: string, trackId: string) => `capacity/student-absences/students/${studentId}/tracks/${trackId}`,
    getStudentAbsenceNew: (studentId: string, trackId: string) => `capacity/student-absences/students/${studentId}/tracks/${trackId}/new`,
  },
};

const attendance = {
  sessionInstances: {
    getAllSessionInstance: 'attendance/session-instances',
    getStats: 'attendance/session-instances/search/statistics/me',
    getStudentSessionInstances: (studentId: string, groupId: string) => `attendance/session-instances/students/${studentId}/groups/${groupId}`,
    getStudentAverageAbsence: (studentId: string, groupId: string) => `attendance/session-instances/groups/${groupId}/students/${studentId}/average-presence`,
    fetchSessionInstanceById: (id: string) => `attendance/session-instances/${id}`,
    markAbsenceById: (sessionInstanceId: string, studentId: string) => `attendance/session-instances/${sessionInstanceId}/presence-statuses/participants/${studentId}`,
    completeSession: (id: string) => `attendance/session-instances/${id}/complete`,
  },
};

const stats = {
  checkpoints: {
    getCheckpointTopStudents: (checkpointAssignmentId: string) => `stats/checkpoints/assignments/${checkpointAssignmentId}/new-scores`,
  },
  instructors: {
    fetchInstructorStats: 'stats/instructors/me',
  },
  labPhases: {
    fetchLabPhaseStats: 'stats/lab-phases',
  },
  students: {
    fetchStudentsPerformanceById: (studentId: string, trackId: string) => `stats/student/tracks/${trackId}/students/${studentId}/performance`,
    getLearnings: (studentId: string, trackId: string) => `stats/student/${studentId}/tracks/${trackId}/skills`,
  },
};

const online = {
  sessions: {
    getActiveSession: 'online/sessions/active/instructors/me',
    getActiveSessionV2: 'online/sessions/active/instructors/me/v2',
    getAllSessions: 'online/sessions/instructors/me',
    getSessionById: (id: string) => `online/sessions/${id}`,
    updateOnlineSessionById: (id: string) => `online/sessions/${id}`,
  },
};

const learn = {
  oneToOnes: {
    fetchOneToOnePreviewMode: (trackId: string, oneToOneAssignmentId: string) => `learn/one-to-one/${oneToOneAssignmentId}/tracks/${trackId}`,
  },
  track: {
    skill: (trackId: string, skillId: string) => `learn/library/tracks/${trackId}/skills/${skillId}`,
  },
  workshops: {
    fetchWorkshopPreviewMode: (trackId: string, workshopId: string) => `learn/workshops/${workshopId}/tracks/${trackId}`,
  },
  checkpoints: {
    fetchCheckpointPreviewMode: (trackId: string, checkpointId: string) => `learn/checkpoints/${checkpointId}/tracks/${trackId}`,
  },
  skills: {
    getSkill: (trackId: string, skillId: string) => `learn/skills/${skillId}/tracks/${trackId}`,
  },
};

const pay = {
  paycheck: {
    getAllEarnings: (id: string) => `pay/paychecks/instructors/${id}`,
    getEarningsSession: (earningId: string, instructorId: string) => `pay/paychecks/${earningId}/instructors/${instructorId}/session-instances`,
    getPayCheckById: (payCheckId: string) => `pay/paychecks/${payCheckId}`,
  },
};

const messaging = {
  discordServers: {
    getDiscordServersToJoin: 'messaging/discord-servers/me',
  },
};

const storage = {
  files: {
    updateProfilePicture: 'storage/files/profiles/v2',
    uploadOnlineSessionResource: (
      onlineSessionId: string,
      onlineSessionResourceId: string,
    ) => `storage/files/online-sessions/${onlineSessionId}/resources/${onlineSessionResourceId}`,
    createOnlineSessionResource: (id: string) => `storage/files/online-sessions/${id}/resources`,
  },
};

const operations = {
  locations: { getMainHsm: 'operation/locations/managers/main/users/me' },
};

const content = {
  resources: {
    getResourcesBySkillId: (skillId: string) => `content/resources/skills/${skillId}`,
  },
};
export default {
  baseApiUrl: () => process.env.REACT_APP_LEARN_ENDPOINT,
  account,
  auth,
  instructor,
  store,
  ranked,
  capacity,
  attendance,
  online,
  stats,
  learn,
  pay,
  messaging,
  storage,
  operations,
  content,
};
