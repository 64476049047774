import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

const getSessionPlans = async (values: {
    query: [];
    trackId: string;
    regimeNumber: number;
  }) => {
  const { query, trackId, regimeNumber } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.sessionPlans.getSessionPlans(trackId, regimeNumber)
        + queryString,
  );
  return result.data;
};

const getSessionPlanDetails = async (values: {
  query: [];
  trackId: string;
  orderNumber: number;
  regimeNumber: number;
}) => {
  const {
    query, trackId, orderNumber, regimeNumber,
  } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.sessionPlans.getSessionPlanDetails(trackId, orderNumber, regimeNumber)
      + queryString,
  );
  return result.data;
};

export default {
  getSessionPlans,
  getSessionPlanDetails,
};
