import { Tooltip } from 'antd';

export const hideLongText = (text: string, maxLength = 20) => {
  const allText = <span className="hide-long-text">{text}</span>;
  if (text.length > maxLength) {
    return (
      <Tooltip placement="right" title={allText}>
        <span className="hide-long-text">
          {`${text.slice(
            0,
            maxLength,
          )}...`}
        </span>
      </Tooltip>
    );
  }

  return text;
};

export function nl2br(str: string, isXhtml?: boolean) {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';
  const str2 = str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1 ${breakTag} $2`);
  return str2;
}

export const formatDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (hours > 0 && minutes === 0) {
    return `${hours} Hrs`;
  } if (hours > 0) {
    return `${hours} H ${minutes} MIN`;
  }
  return `${minutes} MIN`;
};
