/* eslint-disable no-console */
import { Reducer } from 'redux';
import constants from './constants';
import { SessionPlanState } from './types';

export const initialState: SessionPlanState = {
  getSessionPlanLoading: false,
  getSessionPlan: [],
  getSessionPlanErrors: [],
  paginationIndex: 0,
  hasMore: false,
  getSessionPlanDetails: [],
  getSessionPlanDetailsLoading: false,
  getSessionPlanDetailsErrors: [],
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<SessionPlanState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.getSessionPlans.request:
      return {
        ...state,
        getSessionPlanLoading: true,
      };

    case constants.getSessionPlans.success: {
      if (state.paginationIndex === 0) {
        return {
          ...state,
          getSessionPlanLoading: false,
          getSessionPlan: action.data.model,
          paginationIndex: state.paginationIndex + 1,
          hasMore: action.data.model.length === 12,
        };
      }
      return {
        ...state,
        getSessionPlanLoading: false,
        getSessionPlan: [...state.getSessionPlan, ...action.data.model],
        paginationIndex: state.paginationIndex + 1,
        hasMore: action.data.model.length === 12,
      };
    }

    case constants.getSessionPlans.failure: {
      return { ...state, getSessionPlanLoading: false, getSessionPlanErrors: [] };
    }

    case constants.setPaginationIndex.success:
      return {
        ...state,
        paginationIndex: action.data,
      };
    case constants.getSessionPlanDetails.request:
      return {
        ...state,
        getSessionPlanDetailsLoading: true,
      };

    case constants.getSessionPlanDetails.success: {
      return {
        ...state,
        getSessionPlanDetailsLoading: false,
        getSessionPlanDetails: action.data.model,
      };
    }

    case constants.getSessionPlanDetails.failure: {
      return { ...state, getSessionPlanDetailsLoading: false, getSessionPlanDetailsErrors: [] };
    }

    default: {
      return state;
    }
  }
};

export { reducer as sessionPlanReducer };
