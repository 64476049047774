import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';

import profileSaga from './profile/sagas';
import { ProfileState } from './profile/types';
import { profileReducer } from './profile/reducer';
import EarningsSaga from './Earnings/sagas';
import { earningsReducer } from './Earnings/reducer';
import { EarningsState } from './Earnings/types';
import OneToOneListSaga from './oneToOneList/sagas';
import { oneToOneListReducer } from './oneToOneList/reducer';
import { oneToOneListState } from './oneToOneList/types';
import tracksSaga from './tracks/sagas';
import { tracksReducer } from './tracks/reducer';
import checkpointsSaga from './checkpoint/sagas';
import { checkpointsReducer } from './checkpoint/reducer';
import { TracksState } from './tracks/types';
import { CheckpointsState } from './checkpoint/types';
import { automatedOneToOneState } from './automatedOneToOne/types';
import { automatedOneToOneReducer } from './automatedOneToOne/reducer';
import AutomatedOneToOneSaga from './automatedOneToOne/sagas';
import globalSaga from './Global/saga';
import { globalReducer } from './Global/reducer';
import { oneToOneState } from './faceTofaceOneToOne/types';
import { OneToOneReducer } from './faceTofaceOneToOne/reducer';
import faceToFaceOneToOneSaga from './faceTofaceOneToOne/sagas';
import studentSaga from './Student/saga';
import { studentReducer } from './Student/reducer';
import { NPSState } from './NPS/types';
import { NPSReducer } from './NPS/reducer';
import npsSaga from './NPS/sagas';
import statsSaga from './stats/sagas';
import { statsState } from './stats/types';
import { statsReducer } from './stats/reducer';
import groupsSaga from './groups/sagas';
import { GroupsState } from './groups/types';
import { groupsReducer } from './groups/reducer';
import sessionInstancesSaga from './sessionInstance/sagas';
import { SessionInstancesState } from './sessionInstance/types';
import { sessionInstancesReducer } from './sessionInstance/reducer';
import onlineSaga from './online/sagas';
import { OnlineState } from './online/types';
import { onlineReducer } from './online/reducer';
import { globalInitialStateTypes } from './Global/types';
import { initialStateTypes } from './Student/types';
import { TrackPreviewState } from './trackPreview/types';
import { trackPreviewReducer } from './trackPreview/reducer';
import trackPreviewSaga from './trackPreview/saga';
import { LabPhasesReducer } from './LabPhase/reducer';
import labPhasesSaga from './LabPhase/sagas';
import { LabPhasesState } from './LabPhase/types';
import { SessionPlanState } from './sessionPlan/types';
import { sessionPlanReducer } from './sessionPlan/reducer';
import sessionPlanSaga from './sessionPlan/sagas';
// The top-level state object
export interface ApplicationState {
  profile: ProfileState;
  earnings: EarningsState;
  onetoonelist: oneToOneListState;
  tracks: TracksState;
  checkpoints: CheckpointsState;
  automatedOneToOne: automatedOneToOneState;
  global: globalInitialStateTypes;
  oneToOne: oneToOneState;
  student: initialStateTypes;
  nps: NPSState;
  stats: statsState;
  groups: GroupsState;
  sessionInstances: SessionInstancesState;
  online: OnlineState,
  labPhases:LabPhasesState,
  trackPreview: TrackPreviewState;
  sessionPlan: SessionPlanState;
}

export const createRootReducer = () => combineReducers({
  profile: profileReducer,
  earnings: earningsReducer,
  onetoonelist: oneToOneListReducer,
  tracks: tracksReducer,
  checkpoints: checkpointsReducer,
  automatedOneToOne: automatedOneToOneReducer,
  global: globalReducer,
  oneToOne: OneToOneReducer,
  student: studentReducer,
  nps: NPSReducer,
  stats: statsReducer,
  groups: groupsReducer,
  sessionInstances: sessionInstancesReducer,
  online: onlineReducer,
  trackPreview: trackPreviewReducer,
  labPhases: LabPhasesReducer,
  sessionPlan: sessionPlanReducer,
});

export function* rootSaga() {
  yield all([
    fork(profileSaga),
    fork(EarningsSaga),
    fork(tracksSaga),
    fork(checkpointsSaga),
    fork(OneToOneListSaga),
    fork(AutomatedOneToOneSaga),
    fork(globalSaga),
    fork(faceToFaceOneToOneSaga),
    fork(studentSaga),
    fork(npsSaga),
    fork(statsSaga),
    fork(groupsSaga),
    fork(sessionInstancesSaga),
    fork(onlineSaga),
    fork(trackPreviewSaga),
    fork(labPhasesSaga),
    fork(sessionPlanSaga),
  ]);
}
